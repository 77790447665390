import * as React from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';

const ProductsRoot = styled.div`
	margin-top: 5.5rem;
	margin-bottom: 6rem;
`;

const ProductsLayout = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: stretch;
	justify-content: space-between;
	& > :not(:last-child) {
		margin-right: 1rem;
	}
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

interface ProductContainerProps {
	title?: string;
}

export const ProductsContainer: React.FC<ProductContainerProps> = props => {
	const { title, children } = props;

	return (
		<ProductsRoot>
			{title != null && (
				<div>
					<HeaderH3>{title}</HeaderH3>
					<img src={seperatorIndicator} alt="separator"></img>
				</div>
			)}

			<ProductsLayout>{children}</ProductsLayout>
		</ProductsRoot>
	);
};
