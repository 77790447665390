/**
 * Markdown
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */
import * as React from 'react';

interface IMarkdownProps {
	markdown: string;
}
export const Markdown: React.FC<IMarkdownProps> = props => {
	const { markdown } = props;
	return (
		<span>
			{markdown.split(' ').map((t, i) => {
				const cleanedText = t.replace(/(#+ ?)/, '');

				if (t.startsWith('#')) {
					return (
						<span style={{ color: '#fff', fontWeight: 700 }} key={i}>
							{cleanedText}{' '}
						</span>
					);
				} else {
					return cleanedText + ' ';
				}
			})}
		</span>
	);
};
