import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from './Button';
import { Markdown } from './Markdown';
import { HeaderH4 } from './Typography';

const ProductLayout = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	marginBottom: '1rem',
	'@media screen and (max-width: 600px)': {
		marginBottom: '2rem',
	},
});

const AnchorLinkStyled = styled(AnchorLink)({
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
	},
});

const ProductBody = styled.p({
	maxWidth: '400px',

	'@media screen and (max-width: 600px)': {
		maxWidth: '100%',
	},
});

interface ProductProps {
	image: any;
	title?: string;
	details: string;
	url?: string;
	imgWidth?: string;
}

export const Product: React.FC<ProductProps> = props => {
	const { image, title, details, url, imgWidth } = props;
	const { t } = useTranslation();

	return (
		<ProductLayout>
			{/* <Img fixed={image}></Img> */}
			<img style={{ height: '8rem', maxWidth: imgWidth }} src={image} alt="product image" />
			{title != null && <HeaderH4>{title}</HeaderH4>}
			<ProductBody style={{ flex: 1, marginBottom: '2rem', marginTop: '1rem' }}>
				<Markdown markdown={details} />
			</ProductBody>
			{url != null && (
				<AnchorLinkStyled to={url}>
					<Button>{t('button.more')}</Button>
				</AnchorLinkStyled>
			)}
		</ProductLayout>
	);
};
