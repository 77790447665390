import * as React from 'react';
import styled from 'styled-components';

import Applestore from '../svgs/appstore-us.svg';
import Playstore from '../svgs/playstore-us.svg';

const AppstoreRoot = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

const HeaderLogo = styled.img({
	height: 45,
	paddingRight: 20,
});

export const Appstore: React.FC = () => (
	<AppstoreRoot>
		<a
			href="https://apps.apple.com/de/app/meshthings-app/id1530568781"
			target="_blank"
			rel="noreferrer"
		>
			<HeaderLogo src={Applestore} alt="apple store" />
		</a>

		<a
			href="https://play.google.com/store/apps/details?id=io.thingos.meshthings"
			target="_blank"
			rel="noreferrer"
		>
			<HeaderLogo src={Playstore} alt="play store" />
		</a>
	</AppstoreRoot>
);
