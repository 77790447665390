/**
 * Mesh lighting page
 *
 * @author Anja Bisako
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { AppDetails } from '../components/AppDetails';
import { Appstore } from '../components/Appstore';
import { Contact } from '../components/Contact';
import { Container } from '../components/Container';
import { MeshlightingFooter } from '../components/MeshlightingFooter';
import { Page } from '../components/Page';
import { Product } from '../components/Product';
import { ProductsContainer } from '../components/ProductsContainer';
import { HeaderH2, Subtitle, Title } from '../components/Typography';
import { IndexLayout } from '../layouts';
import lightingColors from '../styles/lighting';

import BulletPoint from '../svgs/bullet-point.svg';
import BluetoothLogo from '../svgs/logo-bluetooth-mesh-white.svg';
import MatterLogo from '../svgs/logo-matter-and-thread-white.svg';

const StyledLi = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginTop: '1rem',
});

const StyledUl = styled.div`
	margin-top: 1rem;
`;

const StyledListText = styled.div({
	marginLeft: '1rem',
});

const StyledA = styled.a({
	'&.anchor': {
		display: 'block',
		position: 'relative',
		top: '-8em',
		visibility: 'hidden',
	},
});

const IndexPage: React.FC = () => {
	const { t } = useTranslation('translation');

	return (
		<IndexLayout>
			<Page>
				<Container>
					<div style={{ display: 'flex' }}>
						<Title>
							<HeaderH2>
								Meshlighting - <br />
								{t('slogan')}
								<HeaderH2 style={{ display: 'inline', color: lightingColors.highlight.orange }}>
									.
								</HeaderH2>
							</HeaderH2>
							<Subtitle>{t('slogan.subtitle')}</Subtitle>
						</Title>
					</div>
					<Appstore />
				</Container>

				<Container>
					<StyledA className="anchor" id="Products" href="#Products" />
					<ProductsContainer>
						<Product
							imgWidth={'16rem'}
							url={'/bluetooth'}
							image={BluetoothLogo}
							details={t('competence.bluetooth.description')}
						/>
						<div style={{ maxWidth: '400px' }}>
							<Product
								imgWidth={'100%'}
								url={'/matter'}
								image={MatterLogo}
								details={t('competence.matter.description')}
							/>
						</div>
					</ProductsContainer>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<AppDetails
						title={t('product.app.meshthings')}
						description={t('product.app.meshthings.description')}
						details={
							<StyledUl>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail1')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail2')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail3')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail4')}</StyledListText>
								</StyledLi>
							</StyledUl>
						}
					>
						<div style={{ width: '100%', flex: 1 }}>
							<StaticImage src="../content/meshapp-update.png" alt="Meshthings app" />
						</div>
					</AppDetails>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground" id="Contact">
				<Container>
					<Contact
						title={t('contact.title')}
						text={t('contact.description')}
						buttonText={t('contact.button')}
						url={'mailto:info@thingos.io'}
					/>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<MeshlightingFooter />
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default IndexPage;
