import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH3 } from './Typography';

const AppDetailsContainer = styled.div`
	margin-top: 6rem;
	margin-bottom: 6rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const AppDetailsText = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: 500,
	color: '#fff',
	'&>:not(:last-child)': {
		marginBottom: '1rem',
	},
	marginBottom: '2rem',
	'@media screen and (max-width: 600px)': {
		marginBottom: '4rem',
	},
});

const StyledImage = styled.div({
	display: 'flex',
	width: 320,
	alignSelf: 'flex-end',
	marginBottom: '0rem',
	'@media screen and (max-width: 600px)': {
		marginBottom: 0,
	},
});

interface AppDetailsProps {
	title: string;
	description: string;
	details: React.ReactElement;
}

export const AppDetails: React.FC<AppDetailsProps> = props => {
	const { title, description, details, children } = props;

	return (
		<AppDetailsContainer>
			<AppDetailsText>
				<HeaderH3>{title}</HeaderH3>
				<Body>{description}</Body>
				{details}
			</AppDetailsText>
			<StyledImage>{children}</StyledImage>
			{/* <img src={MeshApp} width={200} /> */}
		</AppDetailsContainer>
	);
};
